import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

const HeroBlock = ({ block, heroSize = "normal" }) => {
  const placeholderImage = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  return (
    <div className="relative w-screen h-[60vh] ">
      <div className="h-[60vh]">
        <Image
          className="object-cover w-full h-full"
          image={
            block.image === undefined || block.image?.filename.length === 0
              ? placeholderImage
              : block.image
          }
        />
      </div>
      <div className="absolute top-0 w-full h-full px-8 text-white">
        <div className="flex flex-col justify-end w-full h-full pt-20 pb-4 lg:pb-8 max-w-screen-lg mx-auto drop-shadow-[0_0_0.5rem_rgba(0,0,0,1)]">
          <Headline headline={block.headline} headlineLevel="h1" />
        </div>
      </div>
    </div>
  )
}

export default HeroBlock
